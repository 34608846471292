<template>
  <div class="schedule">
    <Title :title="title" :description="description"></Title>
    <!-- {{dayList}} {{scheduleList}} -->
    <p v-if="dayList.length==0" class="isblank">敬请期待</p>
    <div class="content-wrapper" v-else>
      <section class="schedule-content">
        <div id="menu" class="pc" style="display: none;" v-if="1">
          <ul>
            <li v-for="(item,index) in dayList" :key="'m'+index">
              <a @click.prevent="toggleDay(index, $event)" :data-time="item.timeStamp" :class="item.timeStamp==selectedDate?'dianji current':'dianji'">
                DAY{{index+1}}
              </a>
            </li>
          </ul>
        </div>
        <div class="schedule-time">
          <div
            v-for="(item, index) in dayList"
            :class="`time-item ${item.timeStamp === selectedDate ? 'selected' : ''}`"
            :data-time="item.timeStamp"
            :key="'schedule_time_'+index"
            @click="toggleDay(index, $event)"
          >
            <h4>DAY{{ index + 1 }}</h4>
            <p>{{ item.time }} {{ item.week }}</p>
          </div>
        </div>
        <div class="schedule-day" ref="schedule" v-for="(scheduleItem, itemId, itemIndex) in scheduleList" :key="itemId">
          <h4 class="day-title">DAY{{ itemIndex + 1 }}</h4>
          <table class="schedule-item opening-list" v-if="openingId && [0,'0'].includes(itemIndex)">
            <div class="time-container">
              <div class="time-list">
                <td class="time-title">
                  <!-- <img src="../assets/img/time.png" /> -->
                  <span class="fenhuichang">分会场</span>
                  <span class="line"></span>
                  <span class="shijian">时间</span>
                </td>
                <slot v-if="openingList && openingList.length > 0">
                  <td class="time-header" v-for="(item, index) in openingList" :key="'schedule_day_o_t_'+index">
                    {{ item.times[0] }}
                    <br />
                    -
                    <br />
                    {{ item.times[1] }}
                  </td>
                </slot>
                <td class="time-header" v-else>09:00<br>-<br>12:00</td>
              </div>
            </div>
            <div class="type-list">
              <div class="type-item">
                <tr class="item-container">
                  <td class="schedule-title" :class="'y-'+global.year">
                    <h4>开幕式</h4>
                  </td>
                  <slot v-if="openingList && openingList.length > 0">
                    <td class="schedule-detail" v-for="(item, index) in openingList" :key="'schedule_day_o_s_'+index">
                      <a>
                        <h4>{{ item.title }}</h4>
                        <div class="lecturer-list">
                          <p class="name">{{ item.lecturer[0] || '' }}</p>
                          <p class="company">{{ item.lecturer[1] || '' }}</p>
                        </div>
                      </a>
                    </td>
                  </slot>
                  <div v-else class="no-detail">
                    <td class="schedule-detail no-schedule">
                      <a>敬请期待</a>
                    </td>
                  </div>
                </tr>
              </div>
            </div>
          </table>
          <table class="schedule-item">
            <div class="time-container">
              <slot v-for="(time, k) in Object.values(timeList)[itemIndex]">
                <div class="time-list" v-if="!(openingId && [0,'0'].includes(itemIndex) && k==='am')" :key="k">
                  <td class="time-title">
                    <!-- <img src="../assets/img/time.png" /> -->
                    <span class="fenhuichang">分会场</span>
                    <span class="line"></span>
                    <span class="shijian">时间</span>
                  </td>
                  <td class="time-header" v-for="(t, index) in time" :key="'schedule_day_'+index">
                    {{ t.split("-")[0] }}
                    <br />
                    -
                    <br />
                    {{ t.split("-")[1] }}
                  </td>
                </div>
              </slot>
            </div>
            <div class="type-list">
              <div class="type-item" v-for="(daySchedule, j) in scheduleItem" :key="j">
                <slot v-for="(item, index) in daySchedule">
                  <tr class="item-container" v-if="!(openingId && [0,'0'].includes(itemIndex) && [0,'0'].includes(index))" :key="'schedule_title_'+index">
                    <!-- 出品人 -->
                    <td class="schedule-title" :class="'y-'+global.year" @click="!isDifference(itemIndex+1, j, index) ? toSubject(item.id) : ''">
                    <!-- <td class="schedule-title" @click="toSubject(item.id)"> -->
                      <h4>{{ item.venueName }}</h4>
                      <p>出品人：{{ item.penName || item.name || '敬请期待' }}</p>
                      <!-- <p @click="item.producerId?toTeacher(item.producerId):''">出品人：{{ item.name }}</p> -->
                    </td>
                    <!-- 普通排课 -->
                    <slot v-if="!isDifference(itemIndex+1, j, index) && item.detail && item.detail.length>0">
                      <slot v-for="(schedule, i) in item.detail">
                        <!-- <td class="schedule-detail no-schedule" :class="'y-'+global.year" :key="'_'+i">敬请期待</td> -->
                        <td v-if="schedule=='敬请期待' || !schedule.courseId" class="schedule-detail no-schedule" :class="'y-'+global.year" :key="'_'+i">
                          <a>敬请期待</a>
                        </td>
                        <td v-else class="schedule-detail" :class="`y-${global.year} ${pptLine?'has-ppt':''}`" :key="i">
                          <!-- 新标签页打开 -->
                          <a :href="courseUrl(schedule.courseId)" target="_blank">
                            <!-- @click="toCourse(schedule.courseId)" -->
                            <!-- {{schedule}} -->
                            <h4>{{ schedule.courseTitle }}</h4>
                            <div class="lecturer-list" v-for="(lecturer, id) in schedule.lecturers" :key="id">
                              <p class="name">{{ lecturer.name }}</p>
                              <p class="company">
                                <!-- {{ lecturer.company }}<br />{{ lecturer.position }} -->
                                {{ lecturer.company&&lecturer.company!=='无' ? lecturer.company : '' }}<br />
                                {{ lecturer.position&&lecturer.position!=='无' ? lecturer.position : '' }}
                                <!-- <slot v-if="lecturer.company==='无'&&lecturer.position==='无'">
                                  {{ lecturer.description }}
                                </slot> -->
                              </p>
                            </div>
                            <!-- 》》》》》》 ppt-blank -->
                            <div class="ppt-status" :class="checkPPTStatus(schedule.pptStatus, itemId, schedule.time)" v-if="pptLine">
                              <p class="mask">PPT</p>
                            </div>
                          </a>
                        </td>
                      </slot>
                    </slot>
                    <!-- 特殊排课 -->
                    <slot v-else-if="isDifference(itemIndex+1, j, index) && scheduleDifference[setDifferenceKey(itemIndex+1, j, index)].courses && scheduleDifference[setDifferenceKey(itemIndex+1, j, index)].courses.length>0">
                      <!-- flex-item -->
                      <td class="schedule-detail schedule-difference link-url" :class="'y-'+global.year" v-if="scheduleDifference[setDifferenceKey(itemIndex+1, j, index)].linkUrl">
                        <a :href="scheduleDifference[setDifferenceKey(itemIndex+1, j, index)].linkUrl" target="_blank">点击报名本专场</a>
                      </td>
                      <td class="schedule-detail schedule-difference" :class="'y-'+global.year" v-for="(schedule, i) in scheduleDifference[setDifferenceKey(itemIndex+1, j, index)].courses" :key="'td_d_'+scheduleDifference[setDifferenceKey(itemIndex+1, j, index)].timeStamp+'_'+i">
                        <p class="time time-top">
                          {{ schedule.times[0] }}<slot v-if="schedule.times[1]"><br>-<br>{{ schedule.times[1] }}</slot>
                        </p>
                        <a>
                          <h4>
                            <!-- <p class="time">{{ schedule.times[0] }}<slot v-if="schedule.times[1]">-{{ schedule.times[1] }}</slot></p> -->
                            {{ schedule.courseTitle }}</h4>
                          <div class="lecturer-list">
                            <p class="name">
                              <slot v-for="(lecturer, id) in schedule.lecturers">
                                <slot v-if="id>0">/</slot>{{lecturer.name}}
                              </slot>
                            </p>
                            <p class="company">
                              <slot v-for="(lecturer, id) in schedule.lecturers">
                                <slot v-if="id>0">/</slot>{{lecturer.company}}
                              </slot>
                              <!-- <br/> lecturer.courseTitle -> 暂不需要显示讲师议题-->
                            </p>
                          </div>
                        </a>
                      </td>
                    </slot>
                    <!-- 敬请期待 -->
                    <div class="no-detail" v-else>
                      <slot v-if="!isDifference(itemIndex+1, j, index)">
                        <td class="schedule-detail no-schedule" :class="'y-'+global.year" v-for="num in 3" :key="'difference_'+itemIndex+'_'+j+'_'+index+'_'+num">
                          <a>敬请期待</a>
                        </td>
                        <td class="schedule-detail no-schedule" :class="'y-'+global.year" v-show="index === 1 || index === '1'">
                          <a>敬请期待</a>
                        </td>
                      </slot>
                      <slot v-else>
                        <td class="schedule-detail no-schedule" :class="'y-'+global.year" v-for="num in scheduleDifference[setDifferenceKey(itemIndex+1, j, index)].length" :key="'difference_'+itemIndex+'_'+j+'_'+index+'_'+num">
                          <a>敬请期待</a>
                        </td>
                      </slot>
                    </div>
                  </tr>
                </slot>
              </div>
            </div>
          </table>
        </div>
      </section>
      <section class="mob-schedule">
        <div class="mob-schedule-time">
          <div
            v-for="(item, index) in dayList"
            class="mob-time-item"
            :class="item.timeStamp == selectedDate ? 'selected' : ''"
            :data-time="item.timeStamp"
            :key="'schedule_day_mob_'+index"
            @click="toggleDay(index, $event)"
          >DAY{{ index + 1 }}</div>
        </div>
        <div v-for="(typeList, i, m_d_i) in scheduleList" :class="`mob-schedule-list ${i === selectedDate ? 'actived' : ''}`" :key="i">
          <div class="mob-type-list">
            <div
              v-if="openingId && selectedDate === dayList[0].timeStamp"
              :class="`mob-type-item is-opening ${'-1' === selectedSchedule ? 'selected' : ''}`"
              :data-id="'-1'"
              @click="toggleSchedule"
            >开幕式</div>
            <div
              v-for="(type, j) in typeList"
              :class="`mob-type-item ${j === selectedSchedule ? 'selected' : ''}`"
              :data-id="j"
              :key="j"
              @click="toggleSchedule"
            >{{ type[0] && type[0].venueName
            }}{{ type[0] && type[0].venueName && type[1] && type[1].venueName && "/"
            }}{{ type[1] && type[1].venueName }}</div>
          </div>
          <!-- 开幕式列表 -->
          <div :class="`mob-speech-wrapper ${'-1' === selectedSchedule && selectedDate === dayList[0].timeStamp ? 'actived' : ''}`" v-if="openingId">
            <div class="mob-speech-list" v-if="openingList && openingList.length > 0">
              <div class="mob-speech-item" v-for="(item,index) in openingList" :key="'openingList_mob_'+index">
                <a>
                  <h4>{{ item.title }}</h4>
                  <div class="mob-lecturer-list">
                    <p class="lecturer">
                      <span>{{ item.lecturer[0] || '' }}</span>
                      {{ item.lecturer[1] || ''}}
                    </p>
                    <p class="time" v-if="item.time">
                      <span class="glyphicon glyphicon-time"></span>
                      {{ item.time.replace(',','-') || '' }}
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div class="mob-speech-list" v-else>
              <div class="mob-speech-item">
                <a>
                  <h4>敬请期待</h4>
                  <div class="mob-lecturer-list">
                    <p class="time">
                      <span class="glyphicon glyphicon-time"></span>
                      09:00-12:00
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <!-- 日程列表 -->
          <div v-for="(type, j) in typeList" :class="`mob-speech-wrapper ${j === selectedSchedule ? 'actived' : ''}`" :key="j">
            <div class="mob-speech-list" v-for="(s, k) in type" :key="k">
              <!-- 常规排课 -->
              <slot v-if="!isDifference(m_d_i+1, j, k)">
                <slot v-if="s.detail && s.detail.length > 0">
                  <!-- <div class="mob-speech-item" @click="toCourse(speech.courseId)" :key="key"> -->
                  <div class="mob-speech-item" v-for="(speech, key) in s.detail" :key="key">
                    <a :href="courseUrl(speech.courseId)" target="_blank">
                      <h4>{{ speech.courseTitle }}</h4>
                      <div class="mob-lecturer-list" v-for="(lecturer, l) in speech.lecturers" :key="l">
                        <p class="lecturer">
                          <span>{{ lecturer.name }}</span>
                          <!-- {{ lecturer.company }} {{ lecturer.position }} -->
                          {{ 
                            lecturer.company&&lecturer.company!=='无' ? lecturer.company : '' 
                          }} {{ 
                            lecturer.position&&lecturer.position!=='无' ? lecturer.position : '' 
                          }}
                        </p>
                      </div>
                      <p class="time">
                        <span class="glyphicon glyphicon-time"></span>
                        {{ speech.time }}
                      </p>
                    </a>
                  </div>
                </slot>
                <div class="mob-speech-item" v-else-if="!(openingId && k==0 && selectedDate === dayList[0].timeStamp)">
                  <a>
                    <h4>敬请期待</h4>
                    <div class="mob-lecturer-list">
                      <p class="time">
                        <span class="glyphicon glyphicon-time"></span>
                        {{ k==0 ? '09:00-12:00' : '13:30-17:50' }}
                      </p>
                    </div>
                  </a>
                </div>
              </slot>
              <!-- 特殊排课 -->
              <slot v-else>
                <div class="mob-speech-item" v-for="(speech, key) in scheduleDifference[setDifferenceKey(m_d_i+1, j, k)].courses" :key="key">
                  <a>
                    <h4>{{ speech.courseTitle }}</h4>
                    <div class="mob-lecturer-list" v-for="(lecturer, l) in speech.lecturers" :key="l">
                      <p class="lecturer">
                        <span>{{ lecturer.name }}</span>
                        {{ 
                          lecturer.company&&lecturer.company!=='无' ? lecturer.company : '' 
                        }} {{ 
                          lecturer.position&&lecturer.position!=='无' ? lecturer.position : '' 
                        }}
                      </p>
                    </div>
                    <!-- <p class="time">
                      <span class="glyphicon glyphicon-time"></span>
                      {{ speech.times[0] }}-{{ speech.times[1] }}
                    </p> -->
                  </a>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </section>
    </div>
    <card-index v-if="$route.query.card" :_kind="'schedule'" :_info="scheduleSubject"></card-index>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Title from "@/components/Title";
import { sid, sidopening, scheduledifference, getSpeechInfoNew, getScheduleInfo, getScheduleInfoFinal, getSue, getSueArr, getSueType, scheduleDifferenceId } from "@/utils/api.js";
import { types } from 'util';

const weekList = {
  0: "星期日",
  1: "星期一",
  2: "星期二",
  3: "星期三",
  4: "星期四",
  5: "星期五",
  6: "星期六",
};
const amTimeStr =
  "9:00-10:00,10:00-11:00,11:00-12:00,8:45-9:35,9:35-10:25,10:25-11:15,11:15-12:05";
const pmTimeStr = "13:30-14:30,14:30-15:30,15:50-16:50,16:50-17:50";
export default {
  name: "NewSchedule",
  components: {
    Title,
    CardIndex: () => import('../components/Card/Index.vue'),
  },
  data() {
    let _this = this;
    return {
      title: "大会日程",
      description: "年度技术创新及研发实践的架构案例",
      qd: '',
      selectedDate: "",
      selectedSchedule: "1", // 移动端选中的日程
      dayList: [], // 日期列表
      scheduleList: {}, // 日程列表
      scheduleSubject: {}, // 海报
      timeList: {},
      totalScheduleList: [],
      scheduleDifference: scheduledifference(_this.global.year),
      openingId: sidopening(_this.global.year), // 开幕式id
      openingList: [], // 开幕式列表
      pptLine: false, // 是否显示ppt提示
    };
  },
  created() {
    this.pptLine = true
    // this.pptLine = this.$route.query && this.$route.query.ppt
  },
  mounted() {
    this.qd = this.$route.query && this.$route.query.qd || '';
    this.getSpeech();
    this.getSchedule();
    if(this.scheduleDifference && typeof this.scheduleDifference==='object') {
      this.getDifference();
    }
  },
  watch: {
    dayList: {
      handler(_new, _old) {
        if(_new && _new.length>0)
        this.$nextTick(() => {
          $(window).off('scroll');
          this.menuScroll()
          window.addEventListener('resize', ()=>{
            $(window).off('scroll');
            if(this.$route.path && this.$route.path.includes('/schedule')){
              this.menuScroll()
            }
          })
        })
      },
      // immediate: true,
      deep: true,
    },
    openingId: {
      handler(newValue, oldValue) {
        // console.log('开幕式：', newValue);
        if (newValue && newValue !== '') {
          this.selectedSchedule = '-1'
          this.getOpeningList(newValue);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    toggleDay(index, e) {
      this.selectedDate = e.currentTarget.dataset.time;
      if (this.openingId && this.selectedDate == this.dayList[0].timeStamp) {
        this.selectedSchedule = "-1";
      } else {
        this.selectedSchedule = "1";
      }
      // window.scrollTo(0, this.$refs.schedule[index].offsetTop - 121);
      this.scrollSmoothTo(this.$refs.schedule[index].offsetTop - 121);
    },
    toggleSchedule(e) {
      this.selectedSchedule = e.target.dataset.id;
    },
    courseUrl(cid) {
      let url = `/${this.global.year}/course?id=${cid}${this.qd.length>0?'&qd='+this.qd:''}`
      return url;
    },
    toCourse(e) {
      if(e)
        this.$router.push({ path: `/${this.global.year}/course`, name: `Course${this.global.year}`, query: { id:e } });
        // query: { id: e.target.dataset.courseid }, // data-courseid
    },
    toTeacher(e) {
      this.$router.push({
        path: `/${this.global.year}/teacher`,
        name: `Teacher${this.global.year}`,
        query: { id:e }
      });
    },
    toSubject(e) {
      this.$router.push({
        path: `/${this.global.year}/subject`,
        name: `Subject${this.global.year}`,
        query:{ id:e }
      });
    },
    // smooth
    scrollSmoothTo (position) {
      if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = function(callback, element) {
          return setTimeout(callback, 17);
        };
      }
      // 当前滚动高度
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // 滚动step方法
      var step = function () {
        // 距离目标滚动距离
        var distance = position - scrollTop;
        // 目标滚动位置
        // console.log('scrollSmoothTo ', position, scrollTop, distance, document.body.offsetTop);
        scrollTop = scrollTop + distance / 5;
        if (Math.abs(distance) < 1) {
          window.scrollTo(0, position);
        } else {
          window.scrollTo(0, scrollTop);
          requestAnimationFrame(step);
        }
      };
      step();
    },
    menuScroll: function () {
      let w_width = window.innerWidth;
      if(w_width > 767) {
        let w_height = window.innerHeight;
        $('#menu').css({ display: 'none' });
        this.showMenu(w_width, w_height);
        $(() => {
          $(window).scroll(() => {
            this.showMenu(w_width, w_height);
          });
        });
      }
    },
    showMenu(w_width, w_height) {
      if(this.$route.path.includes('/schedule')){
        let s_top = document.body.scrollTop || document.getElementsByTagName('body').scrollTop || window.pageYOffset;
        let header_height = document.getElementsByClassName('pc-wrapper')[0].offsetHeight;
        let item2 = (this.$refs && this.$refs.schedule && this.$refs.schedule[1]) || '';
        if(item2){
          let h = item2.offsetTop - (w_height-header_height);
          // console.log('scroll ', $(window).scrollTop(), s_top);
          // console.log(h, this.selectedDate);
          if ($(window).scrollTop() > h) { // 1600
            $("#menu").fadeIn(500);
            this.selectedDate = this.dayList[1].timeStamp;
          } else {
            $("#menu").fadeOut(500);
            this.selectedDate = this.dayList[0].timeStamp;
          }
        }
      }
    },
    // 1. 日期 DAY1 2021.07.30 星期五
    // 2. 每天的日程 DAY1 日程
    // 跳转日程详情页需传入courseid
    // { 1627274400: {1:[{},{}],2:[{},{}]}}
    setDifferenceKey(day, groupid, index) {
      let period = [0,1,'0','1'].includes(index) ? ['am', 'pm'][parseInt(index)] : ''
      let key = `${this.global.year}_${day}_${groupid}_${period}`
      return key;
    },
    isDifference(day, groupid, index) {
      let flag = false;
      let key = ''
      let keys = ''
      if(this.scheduleDifference && typeof this.scheduleDifference==='object') {
        key = this.setDifferenceKey(day, groupid, index);
        keys = Object.keys(this.scheduleDifference);
        if(keys.includes(key)) {
          flag = true;
        }
      }
      return flag;
    },
    getDifference() {
      let url = getSueType + scheduleDifferenceId;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log("difference ", res);
          if (res.errno == 0 && res.data) {
            let type = '';
            let typeList = [];
            for(let item of res.data) {
              type = item.typeName && typeof item.typeName==='string' && item.typeName.toLocaleLowerCase();
              if(type && type.includes('giac')) {
                typeList.push({id:item.id, name:type.split('#')[1]});
              }
            }
            this.getScheduleDifference(typeList);
          }
        })
    },
    getScheduleDifference(typeList) {
      for(let index in Object.keys(this.scheduleDifference)) {
        this.scheduleDifference[Object.keys(this.scheduleDifference)[index]].courses = [];
        this.$forceUpdate();
      }
      // console.log("difference ", typeList, this.scheduleDifference);
      let url = getSueArr + scheduleDifferenceId;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log("difference ", res);
          if (res.errno == 0 && res.data) {
            for(let typeItem of typeList) {
              if(this.scheduleDifference[typeItem.name]) {
                this.scheduleDifference[typeItem.name].courses = [];
                this.scheduleDifference[typeItem.name].timeStamp = new Date().getTime();
                let course = {}, lecturers=[], lecturer='', times=[], titles=[], title='';
                for(let index in res.data[0]) {
                  let course = res.data[0][index];
                  // console.log(course, typeItem, this.scheduleDifference);
                  // schedule-difference
                  if (course.detailType == typeItem.id) {
                    lecturers = course.content && course.content.length>0 && course.content.split(' /// ');
                    if(course.content_note && course.content_note.length>0) {
                      times = (course.content_note.split('scheduleTitle--')[0] || '').replace('time--','').replace(/\r\n/g,'').split('-');
                      // titles = (course.content_note.split('scheduleTitle--')[1] || '').replace(/^\r\n/,'').split('\r\n');
                    }
                    this.scheduleDifference[typeItem.name].courses.push({
                      courseTitle: course.title,
                      lecturers: [],
                      times: times,
                    });
                    let cIndex = this.scheduleDifference[typeItem.name].courses.length - 1
                    for(let lindex in lecturers) {
                      lecturer = lecturers[lindex] || '';
                      // title = titles[lindex] || '';
                      if(lecturer.split(' || ')[0] && lecturer.split(' || ')[0].trim() !== '客户技术高层') {
                        this.scheduleDifference[typeItem.name].courses[cIndex].lecturers.push({
                          name: lecturer.split(' || ')[0] || '',
                          company: lecturer.split(' || ')[1] || '',
                          position: lecturer.split(' || ')[2] || '',
                          // courseTitle: title, // 不需要显示讲师议题
                        });
                      }
                    }
                  }
                }
              }
            }
            this.$forceUpdate();
          }
        })
    },
    filterDayList(dateList) {
      Object.keys(dateList).map((item) => {
        this.dayList.push({
          timeStamp: item,
          time: dayjs(item * 1000).format("YYYY.MM.DD"),
          week: weekList[new Date(item * 1000).getDay()],
        });
      });
    },
    filterSpeechList(speechList) {
      const totalList = [];
      //   根据日期对日程进行分类
      for (const [key, value] of Object.entries(speechList)) {
        // console.log(key);
        // console.log(value);
        const { am: amList, pm: pmList } = value;
        // 遍历整合上午的日程
        for (const [type, val] of Object.entries(amList)) {
          // console.log(type);
          // console.log(val);
          for (const [k, v] of Object.entries(val)) {
            // console.log(k);
            // console.log(v);
            const timeStr = val.time[0] + "-" + val.time[1];
            if (k !== "time" && !Array.isArray(v)) {
              // 每个日程详情内添加会议时间和groupId
              v.timeStamp = key;
              v.time = timeStr;
              v.type = k;
              v.addType = "am";
              totalList.push(v);
            } else {
              // 添加上午的时间列表
              if (this.timeList[key] === undefined) {
                this.timeList[key] = { am: [], pm: [] };
              }
              if (
                this.timeList[key].am.indexOf(timeStr) <= -1 &&
                this.timeList[key].am.length < 3
              ) {
                this.timeList[key].am.push(timeStr);
              }
            }
          }
        }
        // 遍历整合下午的日程
        for (const [type, val] of Object.entries(pmList)) {
          // console.log(type);
          //   console.log(val);
          for (const [k, v] of Object.entries(val)) {
            // console.log(k);
            // console.log(v);
            const timeStr = val.time[0] + "-" + val.time[1];
            if (k !== "time" && !Array.isArray(v)) {
              v.timeStamp = key;
              v.time = timeStr;
              v.type = k;
              v.addType = "pm";
              totalList.push(v);
            } else {
              if (this.timeList[key] === undefined) {
                this.timeList[key] = { am: [], pm: [] };
              }
              if (this.timeList[key].pm.indexOf(timeStr) <= -1) {
                this.timeList[key].pm.push(timeStr);
              }
            }
          }
        }
        this.totalScheduleList = totalList;
        // console.log("totalScheduleList ", this.totalScheduleList);
        // console.log("this.scheduleList ", this.scheduleList);
        let times_arr = [];
        let times_data = [];
        let keyAM = 0
        let keyPM = 1
        for (const [key, value] of Object.entries(this.scheduleList)) {
          for (let k in value) {
            // console.log(value[k]);
            // "am"
            if (value[k][keyAM]) {
              // 设置敬请期待
              // value[k][keyAM].noDetail = [{}, {}, {}];
              // 敬请期待 + 日程
              value[k][keyAM].detail = []
              times_arr = (value[k][keyAM].times && value[k][keyAM].times.split(",")) || [];
              // console.log(times_arr);
              for(let t in times_arr){
                // value[k][keyAM].detail.push('敬请期待');
                value[k][keyAM].detail.push({courseTitle:'敬请期待', time:times_arr[t]});
                // time:this.global.formatTime(key,'Y.M.D')+' '+times_arr[t]
                times_data = this.totalScheduleList.filter(
                  (item) =>
                    item.timeStamp === key &&
                    item.type === k &&
                    item.addType === "am" && 
                    item.time == times_arr[t]
                )
                times_data.length>0 ? value[k][keyAM].detail[t]=times_data[0] : '';
              }
              // console.log(value[k][keyAM].detail)
              // 设置日程详情
              // value[k][keyAM].detail = this.totalScheduleList.filter(
              //   (item) =>
              //     item.timeStamp === key &&
              //     item.type === k &&
              //     item.addType === "am"
              // );
            }
            // "pm"
            if (value[k][keyPM]) {
              // 设置敬请期待
              // value[k][keyPM].noDetail = [{}, {}, {}, {}];
              // 敬请期待 + 日程
              value[k][keyPM].detail = []
              times_arr = (value[k][keyPM].times && value[k][keyPM].times.split(",")) || [];
              // console.log(times_arr);
              for(let t in times_arr){
                // value[k][keyPM].detail.push('敬请期待');
                value[k][keyPM].detail.push({courseTitle:'敬请期待', time:times_arr[t]});
                times_data = this.totalScheduleList.filter(
                  (item) =>
                    item.timeStamp === key &&
                    item.type === k &&
                    item.addType === "pm" && 
                    item.time == times_arr[t]
                )
                times_data.length>0 ? value[k][keyPM].detail[t]=times_data[0] : '';
              }
              // console.log(value[k][keyPM].detail)
              // 设置日程详情
              // value[k][keyPM].detail = this.totalScheduleList.filter(
              //   (item) =>
              //     item.timeStamp === key &&
              //     item.type === k &&
              //     item.addType === "pm"
              // );
            }
          }
        }
        this.$forceUpdate();

        // console.log("scheduleList ", this.scheduleList);
        // console.log(this.timeList);
      }
    },
    filterAllSchedule(list) {
      const obj = {};
      let checkPeriod = (times) => {
        let type = 'am'
        let timeArr = times.split(',')
        // let hourMin = '09'
        let hourMax = '12'
        for (let str of timeArr) {
          for (let time of str.split('-')) {
            let hour = time.split(':')[0]
            // hourMin = Math.min(hourMin, hour);
            hourMax = Math.max(hourMax, hour);
          }
        }
        if (hourMax > '12') {
          type = 'pm'
        }
        return type
      }
      list.map((item) => {
        // console.log(item, checkPeriod(item.times));
        let period = checkPeriod(item.times);
        let periodKey = period==='pm' ? 1 : 0
        if (!(item && item.venueName && item.venueName=='开幕式')) {
          if (obj[item.date]) {
            if (obj[item.date][item.groupId]) {
              // obj[item.date][item.groupId].push(item);
              obj[item.date][item.groupId][periodKey] = item
            } else {
              // obj[item.date][item.groupId] = [item];
              obj[item.date][item.groupId] = {0:{}, 1:{}};
              obj[item.date][item.groupId][periodKey] = item
            }
          } else {
            obj[item.date] = {};
            // obj[item.date][item.groupId] = [item];
            obj[item.date][item.groupId] = {0:{}, 1:{}};
            obj[item.date][item.groupId][periodKey] = item
          }
          // 添加 am/pm
          item.addType = period
          // if (amTimeStr === item.times) {
          //   item.addType = "am";
          // } else if (pmTimeStr === item.times) {
          //   item.addType = "pm";
          // }
        }
      });
      // console.log(list, obj);
      return obj;
    },
    filterSchedule(list) {
      let dayList=[], groupList=[]; // periodList=['am', 'pm'];
      for(let index in list) {
        if(!dayList.includes(list[index].date)) {
          dayList.push(list[index].date);
        }
        if(!groupList.includes(list[index].groupId)) {
          groupList.push(list[index].groupId);
        }
      }
      dayList = dayList.sort((a,b)=>{return a<b;});
      groupList = groupList.sort((a,b)=>{return a<b;});
      let schedule = {};
      for(let d in dayList) {
        schedule[d] = { 'datas': { 'am': {}, 'pm': {} }, 'date': dayList[d] };
        for(let g of groupList) {
          schedule[d]['datas']['am'][g] = {};
          schedule[d]['datas']['pm'][g] = {};
        }
      }
      let flag_date, flag_groupid, flag_period;
      for(let item of list) {
        flag_date = dayList.indexOf(item.date);
        flag_groupid = item.groupId;
        flag_period = this.getPeriod(item.times);
        if(flag_date>=0) {
          if(schedule[flag_date]['datas'][flag_period] && schedule[flag_date]['datas'][flag_period][flag_groupid]) {
            // schedule[flag_date][flag_groupid].push(item);
            schedule[flag_date]['datas'][flag_period][flag_groupid] = item;
          }
        }
      }
      // return schedule;
      this.scheduleSubject = schedule;
    },

    getSpeech: function () {
      // var url = "https://api.msup.com.cn/group/get-group-project/2682";
      let url = getSpeechInfoNew + sid(this.global.year);
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          if (res.errno == 0) {
            // console.log("getSpeech data: ", res.data);
            this.scheduleList = this.filterAllSchedule(res.data);
            // this.scheduleSubject = res.data;
            this.filterSchedule(res.data);
          }
        });
    },
    getPeriod(times) {
      let period = 'am';
      if(times && times.length > 0) {
        let hours = times.slice(0,2);
        if(!hours.includes(':') && !hours.includes('：')) {
          // let minutes = times.slice(3,5);
          if(hours > '12') {
            period = 'pm';
          }
        }
      }
      return period;
    },
    getSchedule: function () {
      // let url = getScheduleInfo + sid(this.global.year);
      let url = getScheduleInfoFinal + sid(this.global.year);
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          if (res.errno == 0) {
            // console.log("schedule ", res.data);
            this.totalScheduleList = this.filterSpeechList(res.data.datas);
            this.filterDayList(res.data.finishing);
            this.selectedDate = this.dayList[0] && this.dayList[0].timeStamp || '';
          }
        });
    },

    getOpeningList (sueid) {
      // let id = 389
      let id = sueid
      this.openingList = []
      let url = getSue + id
      this.$http.jsonp(url).then((data) => {
        return data.json()
      }).then((res) => {
        console.log(res)
        if (res.errno == 0 && res.data && res.data[0] && res.data[0].length > 0) {
          this.openingList = res.data[0].map(item => {
            return {
              title: item.content_note,
              lecturer: item.content.split('-'),
              times: item.desc.split(','),
              time: item.desc
            }
          });
          this.openingList = this.openingList.sort(this.global.toSortKey('time',1,'string'))
          /*content: "刘付强-msup 创始人兼 CEO"
            content_note: "DRIVE 五步走：技术战略驱动的组织系统演进   "
            created_at: "1732513490"
            desc: "11:20,11:50"
            detailType: null
            file: "[]"
            id: "4231"
            isDeleted: "0"
            recommendId: "389"
            sort: "4193"
            title: null
            type: "3"
            typeName: null
            updated_at: "1734401499" */
        }
      })
    },
    checkPPTStatus: function (option, day, hours) {
      // console.log('check_pptStatus ', option, day, hours)
      let status = (this.global.isObjectKeys(option) && Object.keys(option).includes('pptStatus')) ? option.pptStatus : option
      let pptStatus = 'ppt-'
      if (this.pptLine) {
        let hour = (hours && hours.split('-') && hours.split(':')[0]) || '09:00'
        let time = new Date().getTime()
        let firstDay = (day && day * 1000) || (this.days && this.days[0] && this.days[0] * 1000)
        firstDay = new Date(firstDay)
        let h = (hour && hour.split(':') && hour.split(':')[0]) || 0
        let m = (hour && hour.split(':') && hour.split(':')[1]) || 0
        let firstTime = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate(), h, m, 0).getTime()
        // console.log('check_pptStatus ', firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate(), h, m, 0)
        if (time < firstTime) {
          pptStatus += (status && status !== '0') ? 'has' : 'blank'
        } else {
          pptStatus += (status && status !== '0') ? 'has' : 'none'
        }
        // if (!status || status === '0') {
        //   pptStatus += 'none'
        // } else {
        //   pptStatus += (time < firstTime) ? 'blank' : 'has'
        // }
      }
      return pptStatus
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule {
  --ppt-color: #00A0DCB0;
  .isblank {
    width: 100%;
    // line-height: 75px;
    // font-family: PingFangSCBold;
    // font-weight: 500;
    // font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 90px;
    padding-bottom: 40px;
    border-bottom: 1px solid transparent;
  }
}
@media (max-width: 767px) {
  .schedule-content {
    display: none;
  }
  .mob-schedule {
    display: block;
  }
}
@media (min-width: 768px) {
  .schedule-content {
    display: block;
  }
  .mob-schedule {
    display: none;
  }
}
@media (max-width: 750px) {
  .schedule .isblank {
    padding-top: 60px;
  }
}

// pc端样式
.schedule-content {
  padding-top: 45px;
  padding-bottom: 30px;
  #menu {
    width: auto;
    position: fixed;
    z-index: 601;
    bottom: 85px;
    right: 10px;
    margin-left: 400px;
    ul {
      margin-bottom: 0;
      li {
        a {
          display: block;
          margin: 5px 0 0 0;
          font-size: 14px;
          font-weight: bold;
          color: #333;
          width: 65px;
          height: 40px;
          line-height: 40px;
          text-decoration: none;
          text-align: center;
          border: 1px solid #0b70a4;
          background-color: rgba(255, 255, 255, 0.5);
          &:hover, &.current {
            color: #ffffff!important;
            border: 1px solid var(--deep-color);
            background: var(--deep-color);
          }
        }
      }
    }
  }
  .schedule-time {
    display: flex;
    justify-content: center;
    .time-item {
      margin-left: 60px;
      margin-right: 60px;
      padding-left: 50px;
      padding-right: 50px;
      width: 232px;
      height: 100px;
      color: #000;
      border: 1px solid #0b70a4;
      cursor: pointer;
      transition: all 0.3s;
      h4 {
        margin-bottom: 0;
        padding-top: 20px;
        // padding-top: 25px;
        font-size: 36px;
        font-weight: 500;
        color: inherit;
        text-align: center;
      }
      p {
        margin-bottom: 0;
        font-size: 15px;
        font-weight: 500;
        color: inherit;
        white-space: pre;
      }
      &.selected {
        color: #fff;
        background: var(--deep-color);
        p {
          font-weight: 600;
        }
      }
    }
  }
  .schedule-day {
    margin-left: auto;
    margin-right: auto;
    max-width: 1265px;
    .day-title {
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 40px;
      text-align: center;
      padding-top: 45px;
      padding-bottom: 35px;
    }
    .schedule-item {
      display: flex;
      justify-content: space-between;
      --height-td-sch: 280px;
      .time-container {
        border-left: 1px solid #ddd;
        img {
          height: 94px;
        }
      }
      td {
        border: 1px solid #ddd;
        border-left: none;
        border-top: none;
      }
      .time-container .time-list:nth-of-type(1), 
      .type-list .type-item tr:nth-of-type(1) {
        td:nth-of-type(1) {
          border-top: 1px solid #ddd;
        }
      }
      .time-container {
        display: flex;
        flex-direction: column;
        .time-list {
          display: flex;
          flex-direction: column;
          .time-title {
            // padding-right: 42px;
            // width: 145px;
            width: 148px;
            height: 96px;
            // height: 80px;
            position: relative;
            span {
              width: 100%;
              height: fit-content;
              line-height: 1;
              font-size: 16px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.85);
              display: block;
              position: absolute;
              &.fenhuichang {
                text-align: right;
                padding: 0 24px 0 0;
                top: 21px;
              }
              &.shijian {
                text-align: left;
                padding: 0 0 0 24px;
                bottom: 21px;
              }
              &.line {
                width: 76%;
                height: 1px;
                background-color: rgba(0, 0, 0, 0.85);
                top: 50%;
                left: 12%;
                transform: rotateZ(30deg);
              }
            }
          }
          .time-header {
            height: var(--height-td-sch);
            font-size: 16px;
            background: #fafbfc;
            color: #000;
            text-align: center;
            line-height: 24px;
            padding-left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
      .type-list {
        display: flex;
        .type-item {
          display: flex;
          flex-direction: column;
          .item-container {
            display: flex;
            flex-direction: column;
            &:first-child {
              height: 936px;
              // height: 920px;
            }
            &:last-child {
              height: 1216px;
              // height: 1200px;
            }
            .schedule-title {
              width: 186px;
              height: 96px;
              // height: 80px;
              text-align: left;
              color: #000;
              display: flex;
              flex-direction: column;
              justify-content: center;
              // padding-left: 14px;
              // padding-left: 12px;
              // justify-content: top;
              padding-left: 12px;
              // padding-top: 10px;
              // padding-bottom: 10px;
              cursor: pointer;
              &.y-2025sz {
                width: 223px;
              }
              h4 {
                margin-bottom: 0;
                font-weight: 700;
                font-size: 18px;
              }
              p {
                margin-top: 8px;
                // margin-top: 5px;
                margin-bottom: 0;
                font-size: 14px;
                cursor: pointer;
                font-weight: 600;
              }
              &.y-2025sz {
                display: grid;
                justify-content: flex-start;
                align-content: space-between;
                padding-left: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                h4 {
                  font-size: 17px;
                  word-break: break-all;
                  hyphens: auto;
                }
              }
            }
            .schedule-detail {
              flex-grow: 1;
              width: 186px;
              max-height: var(--height-td-sch);
              background: #fafbfc;
              text-align: center;
              font-size: 14px;
              color: #333;
              cursor: pointer;
              position: relative;
              padding: 0;
              &.y-2025sz {
                width: 223px;
              }
              &.has-ppt {
                padding: 15px 0 38px 0;
              }
              a {
                width: 100%;
                height: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
              h4 {
                width: 100%;
                height: 66px;
                color: #1f63af;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                text-align: center;
                padding: 0 10px;
                margin-bottom: 5px;
                // 省略
                word-break: break-all;
                word-wrap: break-word;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .lecturer-list {
                p {
                  margin-top: 0;
                  margin-bottom: 0;
                  text-align: center;
                }
                .name {
                  font-size: 14px;
                  color: #333;
                  font-weight: 700;
                  // margin-top: 10px;
                }
                .company {
                  height: 65px;
                  font-size: 14px;
                  color: #666;
                  padding-top: 5px;
                  padding-left: 5px;
                }
              }
              .ppt-status {
                width: 100%;
                display: flex;
                justify-content: center;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                margin: 4px 0 12px 0;
                .mask {
                  width: 34px;
                  height: 22px;
                  margin: 0;
                  line-height: 18px;
                  font-size: 12px;
                  font-weight: 500;
                  text-align: center;
                  color: #333;
                  // position: absolute;
                  // top: 0;
                  position: relative;
                  z-index: 3;
                  border-radius: 50%;
                  border: 2px solid transparent;
                }
                &.ppt-has {
                  .mask {
                    color: var(--ppt-color);
                    border-color: var(--ppt-color);
                    background-color: transparent; // #ffffffb0; // hsla(0, 0%, 100%, .7);
                  }
                }
                &.ppt-none {
                  .mask {
                    color: #33333380;
                    border-color: #ff000060;
                    background-color: transparent; // #ffffff80;
                    border-radius: 50%;
                    &::before {
                      content: '';
                      display: block;
                      width: 100%;
                      height: 2px;
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%) rotate(30deg);
                      transform-origin: center;
                      background-color: #ff000080;
                      border-radius: 2px;
                    }
                  }
                }
                &.ppt-blank .mask {
                  color: #33333350;
                  border-color: #33333330;
                  background-color: #ffffff;
                }
              }
              &.schedule-difference {
                padding-top: 0;
                padding-bottom: 0;
                &.link-url {
                  height: 40px;
                  max-height: 40px;
                  line-height: 40px;
                  background-color: #0138BC;
                  color: #ffffff;
                  font-weight: bold;
                  padding-top: 0;
                  padding-bottom: 0;
                  animation: blink 2.8s ease infinite;
                  a, a:hover, a:visited {
                    padding-top: 0;
                    padding-bottom: 0;
                    justify-content: flex-start;
                    color: #ffffff;
                  }
                }
                @keyframes blink {
                  0% {
                    opacity: 1;
                    background-color: #0138BC;
                    // background-color: #00A0DC;
                  }
                  50% {
                    opacity: 0.9;
                    background-color: #00A0DC;
                    // background-color: #0138BC;
                  }
                  100% {
                    opacity: 1;
                    background-color: #0138BC;
                    // background-color: #00A0DC;
                  }
                }
                p.time {
                  display: block;
                  width: 100%;
                  line-height: 1;
                  font-size: 14px;
                  font-weight: 400;
                  color: #000;
                  margin-top: 0;
                  margin-bottom: 5px;
                  text-align: left;
                  &.time-top {
                    display: none;
                    width: fit-content;
                    height: 100%;
                    margin-bottom: 0;
                    text-align: center;
                  }
                }
                h4 {
                  height: auto;
                  max-height: max-content;
                  display: block;
                }
                .lecturer-list {
                  .company {
                    height: auto;
                  }
                }
                &.flex-item {
                  display: flex;
                  flex-wrap: nowrap;
                  align-items: baseline;
                  padding: 10px 0 10px 5px;
                  a {
                    padding: 0 5px;
                    justify-content: flex-start;
                  }
                  p.time {
                    display: none;
                    &.time-top {
                      display: block;
                    }
                  }
                  h4 {
                    padding: 0;
                    text-align: left;
                  }
                  .lecturer-list {
                    p {
                      text-align: left;
                    }
                    .company {
                      padding-left: 0;
                    }
                  }
                }
              }
              &.no-schedule {
                padding-top: 0;
                padding-bottom: 0;
              }
            }
            .no-detail {
              background: #fafbfc;
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              .no-schedule {
                flex-grow: 1;
                font-size: 16px;
              }
            }
          }
        }
      }
      &.opening-list {
        --height-td-sch: 120px;
        .time-container {
          .time-list {
            .time-header {
              // height: auto;
              // min-height: var(--height-td-sch);
              // height: var(--height-td-sch);
              &:nth-last-of-type(1) {
                border-bottom: none;
              }
            }
          }
        }
        .type-list {
          width: 100%;
          .type-item {
            width: 100%;
            .item-container {
              height: 100%;
              .schedule-title {
                width: 100%;
                justify-content: center;
                align-content: center;
                align-items: center;
              }
              .schedule-detail {
                width: 100%;
                // max-height: var(--height-td-sch);
                padding-top: 0;
                padding-bottom: 0;
                &:nth-of-type(1) {
                  border-top: none;
                }
                &:nth-last-of-type(1) {
                  border-bottom: none;
                }
                a {
                  h4 {
                    height: auto;
                  }
                  .lecturer-list {
                    p {
                      height: auto;
                    }
                  }
                }
              }
            }
          }
        }
        // 
      }
    }
  }
}

// 移动端样式
.mob-schedule {
  padding-top: 30px;
  .mob-schedule-time {
    display: flex;
    justify-content: center;
    .mob-time-item {
      border: 1px solid var(--deep-color);
      min-width: 135px;
      height: 60px;
      line-height: 60px;
      font-size: 30px;
      font-weight: 500;
      &.selected {
        color: #fff;
        background: var(--deep-color);
      }
    }
  }
  .mob-schedule-list {
    display: none;
    &.actived {
      display: block;
    }
    .mob-type-list {
      padding: 30px 3vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .mob-type-item {
        margin-bottom: 6px;
        background: rgb(249, 249, 249);
        color: #2c3e50;
        width: 46vw;
        height: 60px;
        line-height: 60px;
        padding-left: 4vw;
        padding-right: 4vw;
        font-size: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        &.selected {
          background: rgb(224, 224, 224);
        }
        &.is-opening {
          width: 100%;
        }
      }
    }
  }
  .mob-speech-wrapper {
    display: none;
    &.actived {
      display: block;
    }
    .mob-speech-item {
      margin: 0 auto;
      background: #f9f9f9;
      color: rgb(0, 0, 0);
      width: 94vw;
      padding: 20px;
      margin-bottom: 10px;
      text-align: left;
      a, a:hover, a:visited {
        color: inherit;
        text-decoration: none;
      }
      h4 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .mob-lecturer-list {
        .lecturer {
          margin-bottom: 5px;
          font-size: 14px;
          span {
            font-weight: 500;
            margin-right: 5px;
          }
        }
      }
      .time {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }
}
</style>